(function() {
    "use strict";

    angular.module("app.carts").controller("CartsEditController", EditController);

    EditController.$inject = [
        "$scope",
        "$location",
        "$stateParams",
        "Settings",
        "CartsService",
        "ProductsService",
        "BanksService",
        "OrdersService",
        "SharedData",
        "$uibModal",
    ];

    function EditController($scope, $location, $stateParams, Settings, Service, ProductsService, BanksService, OrdersService, SharedData, $uibModal) {
        /* jshint validthis:true */
        var vm = this;

        vm.loading = true;
        vm.title = "";
        vm.fields = Service.getFields();
        vm.CreditCardfields = Service.getCreditCardFields();
        vm.SharedData = SharedData;
        vm.pay = "";
        vm.settings = Settings;

        function activate() {
            $scope.$watch("vm.CreditCard.CardNumber", function(newValue, oldValue) {
                if (newValue !== undefined) {
                    if (newValue.length > 5) {
                        var newbin = newValue.substring(0, 6);
                        BanksService.getBanktype(newbin).then(function(data) {
                            vm.ActiveBank = data;
                            if (vm.ActiveBank.Bank) {
                                var oneInstallment = vm.ActiveBank.Bank.Installment.find(function(item) {
                                    return item.BankInstallment == 1;
                                });
                                vm.entity.CreditCardPaymentOption = "1_" + vm.ActiveBank.Bank.Id + (oneInstallment ? "_" + oneInstallment.Id : "");
                                vm.entity.ThreeDType = vm.ActiveBank.Bank.ThreeDType === null ? true : vm.ActiveBank.Bank.ThreeDType;
                            } else {
                                vm.entity.CreditCardPaymentOption = "1_" + vm.defaultCheckoutBank.Id;
                                vm.entity.ThreeDType = vm.defaultCheckoutBank.ThreeDType === null ? true : vm.defaultCheckoutBank.ThreeDType;
                            }
                        });
                    }
                }
            });

            $scope.$watch("vm.entity.CreditCardPaymentOption", function(value) {
                if (value) {
                    var valueArray = value.split("_");
                    if (valueArray.length > 1) {
                        vm.entity.Bank = vm.BankList.find((x) => x.Id == valueArray[1]);
                        vm.entity.Installment = valueArray[0];
                        vm.entity.BankId = valueArray[1];
                        vm.entity.InstallmentId = valueArray[2];
                    } else {
                        vm.entity.Installment = "00";
                        vm.entity.BankId = vm.DefaultCheckoutBank ? vm.DefaultCheckoutBank.Id : "";
                    }
                }
            });

            vm.getCart();

            vm.getBankList();
        }

        vm.edit = function() {
            if (vm.entity.SelectedBankId != null) {
                var bankData = vm.BankList.find((x) => x.Id == vm.entity.SelectedBankId);
                if (bankData.UseCheckout == true) {
                    //bank
                    vm.BankPay = true;
                    vm.UseCheckout = false;
                } else {
                    vm.UseCheckout = true;
                    vm.BankPay = false;
                }
            }
            if (vm.form.$valid) {
                vm.loading = true;
                vm.entity.User = null;
                vm.entity.InvoiceAddress = null;
                vm.entity.DeliveryAddress = null;
                vm.entity.CalculatedShippingPrice = null;
                if (vm.entity.CartProduct != null && vm.entity.CartProduct.length > 0) {
                    for (const cartProduct of vm.entity.CartProduct) {
                        cartProduct.Product = null;
                    }
                }
                return Service.edit(vm.entity).then(function() {
                    vm.getCart();
                });
            }
        };

        vm.getCart = function() {
            vm.loading = true;
            Service.get($stateParams.CartId).then(function(data) {
                vm.entity = data;
                vm.loading = false;
            });
        };

        vm.getBankList = function() {
            BanksService.getAll({
                PageSize: -1,
            }).then(function(data) {
                vm.BankList = data.Data.filter(function(item) {
                    return item.Active == true;
                });
            });
        };

        vm.deleteCartProduct = function(cartProduct) {
            vm.loading = true;
            Service.DeleteProduct(cartProduct).then(function() {
                vm.getCart();
            });
        };

        vm.incrementProduct = function(cartProduct) {
            vm.loading = true;
            Service.IncrementProduct(cartProduct).then(function() {
                vm.getCart();
            });
        };

        vm.decrementProduct = function(cartProduct) {
            vm.loading = true;
            Service.DecrementProduct(cartProduct).then(function() {
                vm.getCart();
            });
        };

        vm.refreshProduct = function(text) {
            var params = {
                SearchKey: text,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: "Code",
                SortType: "asc",
                ProductId: vm.entity.ProductId,
                WarehouseId: vm.entity.WarehouseId,
            };
            vm.refreshProductList = [
                {
                    Id: "",
                    Name: "Yükleniyor...",
                },
            ];
            return ProductsService.getProducts(params).then(function(response) {
                if (response.Data.length > 0) {
                    vm.refreshProductList = response.Data;
                } else {
                    vm.refreshProductList = [
                        {
                            Id: "",
                            Name: "Bulunamadı (" + text + ")",
                        },
                    ];
                }
            });
        };

        vm.onSelectProductCallback = function($item) {
            vm.loading = true;
            var cartProduct = {
                ProductId: $item.Id,
                Quantity: 1,
                CartId: vm.entity.Id,
            };
            Service.InsertProduct(cartProduct).then(function() {
                vm.getCart();
            });
        };

        vm.copyLink = function() {
            var copyText = document.getElementById("link-input");
            copyText.disabled = false;
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            copyText.disabled = true;
            alert("Link kopyalandı: " + copyText.value);
        };

        vm.createOrder = function() {
            vm.edit().then(function() {
                var salesOrder = {
                    SalesOrderTypeId: "9e13af47-bff7-4b39-a1a6-73132251f912",
                    PaymentMethodId: "bab50812-0929-44ca-98b9-b415b8619a62",
                    BankId: vm.entity.SelectedBankId,
                    Description: vm.entity.Note,
                    InvoiceAddressId: vm.entity.InvoiceAddressId,
                    DeliveryAddressId: vm.entity.DeliveryAddressId,
                };
                OrdersService.insertWithCart(vm.entity.Id, salesOrder).then(function(data) {
                    $location.path("/orders/" + data.Id);
                });
            });
        };

        vm.PayCreditCard = function() {
            //vm.edit()
            //   .then(function () {
            //       var salesOrder = {
            //           SalesOrderTypeId: "9e13af47-bff7-4b39-a1a6-73132251f912",
            //           PaymentMethodId: "bab50812-0929-44ca-98b9-b415b8619a62",
            //           BankId: vm.entity.SelectedBankId,
            //           Description: vm.entity.Note,
            //           InvoiceAddressId: vm.entity.InvoiceAddressId,
            //           DeliveryAddressId: vm.entity.DeliveryAddressId
            //       };
            //       OrdersService
            //           .payment(vm.entity.Id, salesOrder, vm.CreditCard)
            //           .then(function (data) {

            //               console.log("data",data);
            //              // $location.path('/orders/' + data.Id);
            //           })
            //   });

            var salesOrder = {
                SalesOrderTypeId: "9e13af47-bff7-4b39-a1a6-73132251f912",
                PaymentMethodId: "bab50812-0929-44ca-98b9-b415b8619a62",
                BankId: vm.entity.SelectedBankId,
                Description: vm.entity.Note,
                InvoiceAddressId: vm.entity.InvoiceAddressId,
                DeliveryAddressId: vm.entity.DeliveryAddressId,
            };

            OrdersService.payment(vm.entity.Id, salesOrder, vm.CreditCard, vm.entity.User).then(function(data) {
                vm.openModal(data);
                // $location.path('/orders/' + data.Id);
            });
        };

        vm.createOrderAndPay = function() {
            //vm.edit()
            //    .then(function () {
            //        var salesOrder = {
            //            SalesOrderTypeId: "9e13af47-bff7-4b39-a1a6-73132251f912",
            //            PaymentMethodId: "bab50812-0929-44ca-98b9-b415b8619a62",
            //            BankId: vm.entity.SelectedBankId,
            //            Description: vm.entity.Note,
            //            InvoiceAddressId: vm.entity.InvoiceAddressId,
            //            DeliveryAddressId: vm.entity.DeliveryAddressId
            //        };
            //        OrdersService
            //            .payment(vm.entity.Id, salesOrder)
            //            .then(function (data) {

            //                console.log("data",data);
            //               // $location.path('/orders/' + data.Id);
            //            })
            //    });

            //var url = vm.SharedData.Settings.WebServer + "/Checkout/Checkout?cartId=" + $stateParams.CartId ;
            //console.log("url", url);
            //vm.openModal(url);
            console.log("vm.CreditCard", vm.CreditCard);
            var salesOrder = {
                SalesOrderTypeId: "9e13af47-bff7-4b39-a1a6-73132251f912",
                PaymentMethodId: "bab50812-0929-44ca-98b9-b415b8619a62",
                BankId: vm.entity.SelectedBankId,
                Description: vm.entity.Note,
                InvoiceAddressId: vm.entity.InvoiceAddressId,
                DeliveryAddressId: vm.entity.DeliveryAddressId,
            };

            OrdersService.payment(vm.entity.Id, salesOrder, vm.CreditCard, vm.entity.User).then(function(data) {
                vm.openModal(data);
                // $location.path('/orders/' + data.Id);
            });
        };

        vm.sendWithEmail = function() {
            if (vm.sendEmail) {
                vm.loading = true;
                Service.sendWithEmail(vm.entity.Id, vm.sendEmail).then(function() {
                    vm.sendEmail = null;
                    vm.loading = false;
                });
            }
        };
        // Modal

        vm.modalTitle = "Ödeme İşlemleri";
        vm.modalInstance = null;
        vm.openModal = function(data) {
            vm.modalInstance = $uibModal.open({
                templateUrl: "ModalContentResult.html",
                controller: "ModalPayController",
                controllerAs: "vm",
                resolve: {
                    items: function() {
                        return {
                            data: data,
                        };
                    },
                },
            });
        };

        activate();
    }

    angular.module("app.carts").controller("ModalPayController", ModalPayController);

    ModalPayController.$inject = [
        "$scope",
        "$stateParams",
        "$filter",
        "$location",
        "logger",
        "ExcelService",
        "$uibModal",
        "$uibModalStack",
        "$uibModalInstance",
        "items",
        "$sce",
    ];

    function ModalPayController(
        $scope,
        $stateParams,
        $filter,
        $location,
        logger,
        ExcelService,
        $uibModal,
        $uibModalStack,
        $uibModalInstance,
        items,
        $sce
    ) {
        var vm = this;
        vm.content = items;
        activate();
        function activate() {
            console.log("content ", vm.content.data.Content);

            if (vm.content.data.Success == true) {
                vm.contexthtml = $sce.trustAsHtml(vm.content.data.Content);
            }
            // vm.urlPay = $sce.trustAsHtml(vm.content.Content);
        }
    }
})();

(function () {
    "use strict";

    angular.module("app.categories").factory("CategoriesService", CategoriesService);

    CategoriesService.$inject = [
        "$http",
        "$q",
        "ngAuthSettings",
        "ProductsGeneralService",
        "OptionService",
        "CulturesService",
        "RolesService",
        "SharedData",
    ];

    function CategoriesService($http, $q, ngAuthSettings, ProductsGeneralService, OptionService, CulturesService, RolesService, SharedData) {
        var service = {
            getCategories: getCategories,
            getCategory: getCategory,
            addCategory: addCategory,
            editCategory: editCategory,
            deleteCategory: deleteCategory,
            getCategoryFields: getCategoryFields,
            getCategoryAdditionalFields: getCategoryAdditionalFields,
            getCategoryMediaFields: getCategoryMediaFields,
            getCategoryMedia: getCategoryMedia,
            addCategoryMedia: addCategoryMedia,
            editCategoryMedia: editCategoryMedia,
            removeCategoryMedia: removeCategoryMedia,
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri + "api/Categories/";

        var categoryFields = [
            {
                key: "Code",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.code",
                    label: "Kod",
                    class: "col-xs-4",
                },
            },
            {
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı",
                    class: "col-xs-8",
                },
            },
            {
                key: "Active",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Aktif",
                    resource: "form.active",
                    placeholder: "Aktif",
                    class: "col-xs-3",
                },
            },
            {
                key: "DisplayOrder",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    label: "Sıra",
                    resource: "form.display-order",
                    placeholder: "Sıra",
                    class: "col-xs-3",
                },
            },
            {
                key: "TopCategoryId",
                type: "floatSelect",
                templateOptions: {
                    label: "Üst Kategori",
                    resource: "form.top-category",
                    options: [
                        {
                            Name: "...",
                        },
                    ],
                    class: "col-xs-6",
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        ProductsGeneralService.getCategories().then(function (response) {
                            var opt = [
                                {
                                    Name: "",
                                },
                            ];
                            $scope.to.options = opt.concat(response);
                        });
                    },
                ],
            },
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "Açıklama",
                    placeholder: "İçerik",
                    resource: "form.description",
                },
            },
            {
                key: "Description2",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "Açıklama 2",
                    resource: "form.description2",
                    placeholder: "İçerik",
                },
            },
            {
                key: "ShowMenu",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Menüde Göster",
                    resource: "form.show-menu",
                    placeholder: "Menüde Göster",
                    class: "col-xs-4",
                },
            },
            {
                key: "CultureId",
                type: "floatSelect",
                defaultValue: SharedData.Settings.CurrentCultureId,
                templateOptions: {
                    required: true,
                    label: "Dil",
                    labelProp: "Description",
                    resource: "form.culture",
                    options: [
                        {
                            Name: "Yükleniyor...",
                        },
                    ],
                    class: "col-xs-8",
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        CulturesService.getAll({
                            PageSize: -1,
                        }).then(function (response) {
                            $scope.to.options = response.Data;
                            if (!$scope.model.CultureId && response.Data.length <= 1) {
                                $scope.model.CultureId = response.Data[0].Id;
                            }
                        });
                    },
                ],
            },
            {
                key: "Roles",
                type: "float-async-ui-select-multiple",
                templateOptions: {
                    label: "Kategori Rolü",
                    resource: "form.category-role",
                    labelProp: "Name",
                    valueProp: "Id",
                    options: [],
                    placeholder: "Kategori Rolü",
                    refresh: function (text, options, model) {
                        var params = {
                            Name: text,
                            PageSize: 25,
                        };
                        if (model) {
                            params.Id = model.Id;
                        }
                        return RolesService.getAll(params).then(function (response) {
                            options.templateOptions.options = response.Data;
                        });
                    },
                    refreshDelay: 2,
                },
            },
            {
                key: "IconId",
                type: "floatSingleImage",
                templateOptions: {
                    label: "İkon",
                    resource: "form.icon",
                    valueKey: "Id",
                    valueModel: "Icon.FullPath",
                    class: "col-xs-6",
                },
            },
            {
                key: "CatalogMediaId",
                type: "floatSingleMedia",
                templateOptions: {
                    label: "Katalog",
                    placeholder: "Katalog",
                    resource: "form.catalog",
                    valueKey: "Id",
                    valueModel: "CatalogMedia.Name",
                    class: "col-xs-6",
                },
            },
            {
                key: "Options",
                type: "floatMultiSelect",
                templateOptions: {
                    label: "Özellikler",
                    resource: "form.options",
                    options: [
                        {
                            Name: "Yükleniyor...",
                        },
                    ],
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        OptionService.getAll({
                            PageSize: -1,
                        }).then(function (response) {
                            $scope.to.options = response.Data;
                        });
                    },
                ],
            },
            {
                key: "MetaTitle",
                type: "floatInput",
                templateOptions: {
                    label: "Seo title",
                    resource: "form.meta-title",
                    type: "text",
                    class: "col-xs-6",
                },
            },
            {
                key: "MetaKeyword",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.meta-keyword",
                    label: "Seo keywords",
                    class: "col-xs-6",
                },
            },
            {
                key: "MetaDescription",
                type: "floatTextarea",
                templateOptions: {
                    label: "Seo description",
                    resource: "form.meta-description",
                    maxlength: 160,
                },
            },
            {
                key: "NormalizedName",
                type: "floatInput",
                templateOptions: {
                    label: "Arama Motoru Sayfa Adı",
                    resource: "form.normalized-name",
                    type: "text",
                },
            },
        ];

        var categoryMediaFields = [
            {
                key: "MediaList",
                type: "horizontalMultipleImage",
                templateOptions: {
                    label: "Resim",
                    resource: "form.image",
                    valueModel: "Media.FileName",
                },
            },
            {
                key: "IsMain",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Ana Resim",
                    resource: "form.is-main-media",
                    placeholder: "Ana Resim",
                    class: "col-xs-4",
                },
            },
            {
                key: "ShowSlider",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Sliderda Göster",
                    resource: "form.show-slider",
                    placeholder: "Sliderda Göster",
                    class: "col-xs-4",
                },
            },
            {
                key: "IsMenuImage",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Menü Resmi",
                    resource: "form.is-menu-image",
                    placeholder: "Menü Resmi",
                    class: "col-xs-4",
                },
            },
            {
                key: "DisplayOrder",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    resource: "form.display-order",
                    label: "Sıra",
                    placeholder: "Sıra",
                    class: "col-xs-3",
                },
            },
            {
                key: "Link",
                type: "floatInput",
                templateOptions: {
                    resource: "form.link",
                    label: "Link",
                    placeholder: "Link",
                    class: "col-xs-9",
                },
            },
        ];

        var categoryAdditionalFields = [
            {
                key: "Description",
                type: "floatTinyMCE",
                templateOptions: {
                    label: "Ürün Açıklama ",
                    placeholder: "İçerik",
                    resource: "form.description",
                },
            },
            {
                key: "ProductDefaultMetaTitle",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Ürün Seo keywords",
                    resource: "form.product-seo-keywords",
                },
            },
            {
                key: "ProductDefaultMetaKeyword",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Ürün Seo keywords",
                    resource: "form.product-seo-keywords",
                },
            },
            {
                key: "ProductDefaultMetaDescription",
                type: "floatTextarea",
                templateOptions: {
                    label: "Ürün Seo description",
                    resource: "form.product-seo-description",
                    maxlength: 160,
                },
            },
            {
                key: "ProductDefaultPrice",
                type: "floatInput",
                templateOptions: {
                    label: "Fiyat",
                    resource: "form.price",
                    placeholder: "Fiyat ",
                    moneyMask: 2,
                },
            },
            {
                key: "ProductDefaultMinimumQuantity",
                type: "floatInput",
                defaultValue: "1",
                templateOptions: {
                    label: "Ürün Minimum Satış Miktarı",
                    resource: "form.minPriceProduct",
                    placeholder: "Ürün Minimum Satış Miktarı",
                    numberMask: 2,
                },
            },
        ];

        return service;

        /////////////////////////////////////////

        function getCategoryFields() {
            return categoryFields;
        }

        function getCategoryMediaFields() {
            return categoryMediaFields;
        }

        function getCategoryAdditionalFields() {
            return categoryAdditionalFields;
        }

        function getCategories(filterParams) {
            var deferred = $q.defer();

            $http
                .get(serviceBase, {
                    params: filterParams,
                })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategory(Id) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + Id)
                .then(getProductComplete)
                .catch(getProductFailed);

            function getProductComplete(response) {
                deferred.resolve(response.data);
            }

            function getProductFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addCategory(category) {
            var deferred = $q.defer();

            $http.post(serviceBase, category).then(addComplete).catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCategory(category) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + category.Id, category)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function deleteCategory(category) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + category.Id, category)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function getCategoryMedia(categoryId) {
            var deferred = $q.defer();

            $http
                .get(serviceBase + categoryId + "/CategoryMedia")
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function addCategoryMedia(entity) {
            var deferred = $q.defer();

            $http
                .post(serviceBase + entity.CategoryId + "/CategoryMedia", entity)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function editCategoryMedia(entity) {
            var deferred = $q.defer();

            $http
                .put(serviceBase + entity.CategoryId + "/CategoryMedia", entity)
                .then(complete)
                .catch(failed);

            function complete(response) {
                deferred.resolve(response.data);
            }

            function failed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function removeCategoryMedia(entity) {
            var deferred = $q.defer();

            $http
                .delete(serviceBase + entity.CategoryId + "/CategoryMedia/" + entity.MediaId, entity)
                .then(removeCategoryMediaComplete)
                .catch(removeCategoryMediaFailed);

            function removeCategoryMediaComplete(response) {
                deferred.resolve(response.data);
            }

            function removeCategoryMediaFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }
    }
})();

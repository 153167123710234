(function () {
    "use strict";

    angular.module("app.option").controller("OptionListController", OptionListController);

    OptionListController.$inject = [
        "$scope",
        "$location",
        "OptionService",
        "ProductsGeneralService",
        "NgTableParams",
        "OptionValuesService",
        "MediaService",
        "Settings",
        "$uibModal"
    ];

    function OptionListController(
        $scope,
        $location,
        OptionService,
        ProductsGeneralService,
        NgTableParams,
        OptionValuesService,
        MediaService,
        Settings,
        $uibModal
    ) {
        var vm = this;
        vm.path = $location.absUrl();
        activate();

        function activate() {
            vm.firstLoad = true;
            vm.tableParams = new NgTableParams(
                angular.extend({
                    page: 1,
                    count: 18,
                    sorting: {
                        DisplayOrder: "asc"
                    }
                }, $location.search()), {
                    getData: function (params) {
                        if (!vm.firstLoad) {
                            $location.search(params.url());
                        }
                        vm.firstLoad = false;
                        var filterData2 = params.filter();
                        var filterData = {};
                        filterData.PageIndex = params.page();
                        filterData.PageSize = params.count();
                        filterData.SortProperty = Object.keys(params.sorting())[0];
                        filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                        var keys = Object.keys(filterData2);
                        for (var i = 0; i < keys.length; i++) {
                            filterData[keys[i]] = filterData2[keys[i]];
                        }
                        return OptionService.getAll(filterData).then(function (data) {
                            params.total(data.FilteredCount);
                            return data.Data;
                        });
                    }
                }
            );

            ProductsGeneralService.getOptionControlTypes().then(function (data) {
                vm.optionControlTypes = data;
            });
        }

        vm.getOptionList = function () {
            OptionService.getAll({
                PageSize: -1
            }).then(function (data) {
                vm.optionList = data.Data;
            });
        };

        vm.settings = Settings;

        vm.cancel = cancel;
        vm.del = del;
        vm.save = save;
        vm.add = add;

        ///////////////////////////////////

        function cancel(row, rowForm) {
            vm.tableParams.reload();
        }

        function del(row) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                OptionService.del(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function save(row, rowForm) {
            row.Icon = null;
            row.OptionControlType = null;
            row.TopOption = null;
            if (row.Id) {
                OptionService.edit(row).then(function (data) {
                    vm.tableParams.reload();
                });
            } else {
                OptionService.add(row).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        }

        function add() {
            vm.tableParams.data.unshift({
                Name: "",
                isEditing: true
            });
            vm.getOptionList();
        }

        ////////////////////////////////Option Value

        vm.optionValueFields = OptionValuesService.getFields();
        vm.optionValueEntity = {};

        vm.addOptionValue = function (option) {
            vm.optionValueEntity = {
                OptionId: option.Id,
                Settings: {}
            };
            vm.optionValueSettingsFields = JSON.parse(option.OptionControlType.Settings || "{}");
        };

        vm.editOptionValue = function (optionValue, option) {
            vm.optionValueEntity = angular.copy(optionValue);
            vm.optionValueSettingsFields = JSON.parse(option.OptionControlType.Settings || "{}");
            vm.optionValueEntitySettings = JSON.parse(vm.optionValueEntity.Settings || "{}");
        };

        vm.deleteOptionValue = function (optionValue) {
            if (confirm("Silmek istediğinize emin misiniz?")) {
                OptionValuesService.del(optionValue).then(function (data) {
                    vm.tableParams.reload();
                });
            }
        };

        vm.optionValueCancel = function () {
            vm.optionValueEntity = null;
        };

        vm.optionValueSubmit = function (option) {
            vm.loading = true;
            if (vm.optionValueEntity.Id) {
                vm.optionValueEntity.Option = null;
                vm.optionValueEntity.Settings = JSON.stringify(vm.optionValueEntitySettings || "{}");
                OptionValuesService.edit(vm.optionValueEntity)
                    .then(function () {
                        vm.tableParams.reload();
                        vm.optionValueEntity = null;
                        vm.optionValueSettingsFields = null;
                        vm.optionValueEntitySettings = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            } else {
                vm.optionValueEntity.Settings = JSON.stringify(vm.optionValueEntitySettings || "{}");
                OptionValuesService.add(vm.optionValueEntity)
                    .then(function () {
                        vm.tableParams.reload();
                        vm.optionValueEntity = null;
                        vm.optionValueSettingsFields = null;
                        vm.optionValueEntitySettings = null;
                        vm.loading = false;
                    })
                    .catch(function () {
                        vm.loading = false;
                    });
            }
        };

        // Image
        vm.fileChanged = function (that) {
            var file = that.files[0];
            var formData = new FormData();
            formData.append("File", file, file.name);
            MediaService.add(formData).then(function (data) {
                vm.tableParams.data[that.attributes["data-index"].value].Icon = {
                    FileName: data.FullPath
                };
                vm.tableParams.data[that.attributes["data-index"].value].IconId = data.Id;
            });
        };

        vm.cancelImage = function (entity) {
            entity.Icon = null;
            entity.IconId = null;
        };

        vm.getProperty = function (object, propertyName) {
            var parts = propertyName.split("."),
                length = parts.length,
                i,
                property = object || this;

            for (i = 0; i < length; i++) {
                property = property[parts[i]];
            }

            return property;
        };

        vm.refresh = function () {
            vm.loading = true;
            vm.tableParams.reload();
            vm.loading = false;
        };

        vm.tinymceOptions = {
            image_advtab: true,
            image_dimensions: false,
            image_title: true,
            relative_urls: false,
            forced_root_block: "",
            height: "400",
            fontsize_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
            lineheight_formats: "2px 4px 6px 8px 10px 12px 14px 18px 24px 36px",
            images_upload_credentials: true,
            plugins: "image code imagetools advlist autolink lists link image charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample toc lineheight",
            toolbar1: " styleselect sizeselect fontselect fontsizeselect lineheightselect  | bold italic strikethrough forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media codesample",
            content_css: [
                globalSettings.WebServer + "/themes/" + globalSettings.ThemeName + "/dist/css/site.css"
            ],
            entity_encoding: "raw",
            file_picker_callback: function (cb, value, meta) {
                this.settings.activateFileManager(cb, value, meta);
            },
            setup: function (ed) {
                if (
                    $("#" + ed.id)
                    .closest("fieldset")
                    .prop("disabled")
                ) {
                    ed.settings.readonly = true;
                }
            }
        }

        var $uibModalInstance;
        vm.tinymceOptions.activateFileManager = function (cb) {
            vm.tinycb = cb;

            $uibModalInstance = $uibModal.open({
                windowClass: "filemanager-modal",
                template: '<div class="modal-body" id="modal-body-{{name}}">' +
                    "<angular-filemanager></angular-filemanager>" +
                    "</div>"
            });

            function pickItem(evt) {
                var item = evt.detail.item;
                vm.tinycb(Settings.MediaServer + "/" + item.path.join("/") + "/" + item.name, {
                    title: item.name
                });
                $uibModalInstance.dismiss();
                window.removeEventListener("onPickItem", pickItem);
            }
            window.addEventListener("onPickItem", pickItem, false);
        };
    }
})();
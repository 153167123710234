

(function () {
    'use strict';

    angular
        .module('app.shipmentProvider')
        .factory('ShipmentProviderService', Service);

    Service.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', '$timeout'];

    function Service($http, $q, ngAuthSettings, logger, config, $timeout) {
        var service = {
            getAll: getAll,
            get: get,
            add: add,
            edit: edit,
            del: del,
            copyToRole: copyToRole,
            getFields: getFields
        };

        var serviceBase = ngAuthSettings.apiServiceBaseUri;
        var endpoint = serviceBase + 'api/ShipmentProvider/';

        var fields = [
            {
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    label: "Adı",
                    resource: "form.name",
                    placeholder: "Adı",
                    class: "col-xs-6"
                }
            },
            {
                key: "DefaultDSPrice",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    resource: "form.defaultDSPrice",
                    label: "Varsayılan Desi Fiyatı",
                    placeholder: "Varsayılan Desi Fiyatı",
                    class: "col-xs-6"
                }
            },
            {
                key: "Active",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Aktif",
                    resource: "form.active",
                    placeholder: "Aktif",
                    class: "col-xs-6"
                }
            },
            {
                key: "Default",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Varsayılan",
                    resource: "form.default",
                    placeholder: "Varsayılan",
                    class: "col-xs-6"
                }
            },
            {
                key: "PayatTheDoor",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Kapıda Ödeme",
                    resource: "form.pay-at-door",
                    placeholder: "Kapıda Ödeme",
                    class: "col-xs-6"
                }
            },
            {
                key: "UsePercentageForCommission",
                type: "floatCheckbox",
                templateOptions: {
                    label: "Yüzde Kullan(Komisyon için)",
                    resource: "form.use-percentage-for-commission",
                    placeholder: "Yüzde Kullan(Komisyon için)",
                    class: "col-xs-6"
                }
            },
            {
                key: "PayatTheDoorCommission",
                type: "floatInput",
                templateOptions: {
                    type: "number",
                    label: "Kapıda Ödeme Komisyonu",
                    resource: "form.pay-at-door-commission",
                    placeholder: "Kapıda Ödeme Komisyonu",
                    class: "col-xs-12"
                }
            },
            {
                key: "IconId",
                type: "horizontalSingleImage",
                templateOptions: {
                    label: "Resim",
                    resource: 'form.image',
                    valueKey: "Id",
                    valueModel: "Icon.FullPath"
                }
            }
        ];

        return service;

        ///////////////////////////////

        function getFields() {
            return fields;
        }

        function getAll(filterParams) {
            var deferred = $q.defer();
            $http.get(endpoint + "all", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function get(id) {
            var deferred = $q.defer();

            $http.get(endpoint + id)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function add(entity) {

            var deferred = $q.defer();

            $http.post(endpoint, entity)
                .then(addComplete)
                .catch(addFailed);

            function addComplete(response) {
                deferred.resolve(response.data);
            }

            function addFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;

        }

        function edit(entity) {

            var deferred = $q.defer();

            $http.put(endpoint + entity.Id, entity)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;

        }

        function copyToRole(shipmentProviderId, roleId) {
            var deferred = $q.defer();

            $http
                .put(endpoint + "CopyToRole/" + shipmentProviderId + "/" + roleId)
                .then(editComplete)
                .catch(editFailed);

            function editComplete(response) {
                deferred.resolve(response.data);
            }

            function editFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }

        function del(entity) {

            var deferred = $q.defer();

            $http.delete(endpoint + entity.Id, entity)
                .then(deleteComplete)
                .catch(deleteFailed);

            function deleteComplete(response) {
                deferred.resolve(response.data);
            }

            function deleteFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;

        }
    }
})();

(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .controller('ErpController', ErpController);

    ErpController.$inject = ['$scope', '$stateParams', '$filter', '$location', 'logger', 'GetProductsService', 'NgTableParams', '$uibModal', '$uibModalStack', 'ProductsGeneralService', 'OptionService', 'ProductsService'];

    function ErpController($scope, $stateParams, $filter, $location, logger, GetProductsService, NgTableParams, $uibModal, $uibModalStack, ProductsGeneralService, OptionService, ProductsService) {
        var vm = this;
        activate();
        vm.categoryDisable = true;
        vm.GetProductPage = true;
        vm.CategoryDetailsDisable = false;
        vm.OptionDetailsDisable = false;
        vm.ProductDetailsDisable = false;
        vm.startDate="";
        vm.IsUpdate=false;

        vm.endDate="";
        function activate() {
            //  vm.loading = false;
            vm.range;
        }

        var clicked = false;
        vm.SelectAll = function () {
            if (clicked != true) {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSave != true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSave = true;
                }
                clicked = true;
            }
            else {
                var nonChecked = vm.tableParams.data.filter(x => x.IsSave == true);
                for (var i = 0; i < nonChecked.length; i++) {
                    nonChecked[i].IsSave = undefined;
                }
                clicked = false;
            }
        }


        vm.filteredProductList = [];
        vm.refreshFilterProduct = function (search) {
            var params = {
                SearchKey: search,
                PageSize: 18,
                PageIndex: 1,
                SortProperty: 'Name',
                SortType: 'asc'
            };
            vm.filteredProductList = [{
                Id: '',
                Name: 'Yükleniyor...'
            }];
            return ProductsService.getProducts(params)
                .then(function (response) {
                    if (response.Data.length > 0) {
                        vm.filteredProductList = response.Data;
                    } else {
                        vm.filteredProductList = [{
                            Id: '',
                            Name: 'Bulunamadı (' + search + ')'
                        }];
                    }
                });
        }


        vm.ErpFields = GetProductsService.getErpFields();


        vm.StartGetCategory = function () {

            if (Object.keys(vm.Erp).length > 0) {
                vm.categoryDisable = false;


            }

        };

        vm.StartGetProduct = function (data) {
            vm.loadingDetailsProduct = true;
            vm.loading = true;
            GetProductsService.TransferProductErp(vm.ErpFields).then(function (response) {

                var title = "Ürün Kayıt";
                var message = response.insertProductCount + " adet ürün kayıt edildi. \r\n" + response.UpdateProductCount + " adet ürün güncellendi. \r\n" + response.ErrorProductCount + " üründe hata oluştu.";

                toastr.info(message, title);
                vm.loading = false;
                vm.loadingDetailsProduct = true;
            });
        };

        vm.StartGetProductDetails = function (item) {

            vm.CategoryDetailsDisable = true;
            vm.GetProductPage = false;
            vm.loadingDetails = true;

            vm.tableParams.reload();



            ProductsGeneralService.getCategories().then(function (response) {
                vm.LocalCategoryList = getTreeData(response, null);
            });



        }


        function getTreeData(options, topProp) {
            var data = options.filter(obj => obj["TopCategoryId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }


        vm.MarketOptionList = [];

        vm.MarketProductList = [];

        vm.tableParams = new NgTableParams(
            angular.extend({
                page: 1,
                count: 10,
                sorting: {
                    Name: "asc"
                }
            }, {}), {
            getData: function (params) {
                if (!vm.firstLoad) {
                    $location.search(params.url());
                }
                vm.firstLoad = false;
                var filterData2 = params.filter();
                var filterData = {};
                filterData.PageIndex = params.page(),
                    filterData.PageSize = params.count(),
                    filterData.SortProperty = Object.keys(params.sorting())[0],
                    filterData.SortType = params.sorting()[Object.keys(params.sorting())[0]];
                var keys = Object.keys(filterData2);
                for (var i = 0; i < keys.length; i++) {
                    filterData[keys[i]] = filterData2[keys[i]];
                }
                    filterData.System = vm.ErpFields;
                    return GetProductsService.getCategoriesErp(filterData).then(function (data) {
                    params.total(data.FilteredCount);
                    vm.loadingDetails = false;
                    return data;
                });
            }
        });


        vm.getOptionList = function () {
            OptionService.getAll({ PageSize: -1 }).then(function (data) {
                vm.optionList = data.Data;
            });

            var filterData = {};
            filterData.PageSize = 10000;
            ProductsService.getProducts(filterData).then(function (data) {
                vm.LocalProductsList = getTreeData1(data.Data, null);

            });

        };

        function getTreeData1(options, topProp) {
            var data = options.filter(obj => obj["BaseProductId"] === topProp);
            if (data.length <= 0) {
                return [];
            }
            return data
                .map(obj => {
                    var rObj = {};
                    rObj.id = obj["Id"];
                    rObj.name = obj["Name"];
                    rObj.children = getTreeData(options, obj.Id) || [];
                    return rObj;
                });
        }

        vm.baseProductFilter = function (item) {
            if (item.BaseProductId === null) return true;
            return false;
        }


        vm.Detailscancel = function () {



            vm.GetProductPage = true;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = false;
            vm.OptionValueDetailsDisable = false;
            vm.ProductDetailsDisable = false;
            
        }
        vm.CatNext = function (data) {
            vm.CategoryDetailsDisable = false;
            // vm.getOptionList();
            vm.OptionDetailsDisable = false;
            vm.OptionValueDetailsDisable = false;
            vm.ProductDetailsDisable = true;
            vm.loadingDetailsProduct = true;
            var System = vm.ErpFields;
            vm.range();
/*
            GetProductsService.getSaveCategory(data.data).then(function (response) {
                
                
                GetProductsService.getProductErp(System).then(function (data) {
                vm.allData = data;
                for (var i = 0; i < vm.allData; i++) {
                    vm.allData[i].IsOpen = false;
                }
                
                vm.loadingDetailsProduct = false;
             });

            });
*/

        }


        vm.ErpProductAllUpdate = function (data) {
            vm.loadingDetailsProduct = true;
            vm.loading = true;
            GetProductsService.ErpProductAllUpdate(data).then(function (response) {
                vm.loadingDetailsProduct = false;

                var title = "Ürün Kayıt";
                var message = response.insertProductCount + " adet ürün kayıt edildi. \r\n" + response.UpdateProductCount + " adet ürün güncellendi. \r\n" + response.ErrorProductCount + " üründe hata oluştu.";

                toastr.info(message, title);
                vm.loading = false;

               
            });
        }

        

        vm.range = function () {
            vm.loadingDetailsProduct = true;
            if (vm.endDate == "" || vm.endDate == undefined || vm.endDate == null) {
                vm.endDate = new Date();
                if (vm.startDate == "") {
                    vm.startDate =new Date(vm.endDate.getTime() - 7 * 24 * 60 * 60 * 1000);
                }
               
            }
           
            var CheckedList = vm.tableParams.data.filter(x => x.IsSave == true);
            var CategoryList = [];
            for (var x = 0; x < CheckedList.length; x++) {
            CategoryList.push(CheckedList[x].CategoryCode);
            }
            var startDate = vm.startDate.getDate() + '.' + (vm.startDate.getMonth()+1) + '.' + vm.startDate.getFullYear();
            var endDate = vm.endDate.getDate() + '.' + (vm.endDate.getMonth()+1) + '.' + vm.endDate.getFullYear();
            GetProductsService.getProductErpRange(vm.IsUpdate,startDate, endDate, CategoryList).then(function (data) {

                vm.RangeProduct = data;
                vm.loadingDetailsProduct = false;
            })
        }     
       

        vm.ReturnCategory = function () {

            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = true;
            vm.OptionDetailsDisable = false;
            vm.OptionValueDetailsDisable = false;

        }

        vm.ReturnOption = function () {

            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = false;

        }

        vm.ReturnOptionValue = function () {
            vm.GetProductPage = false;
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = true;
            vm.ProductDetailsDisable = false;
        }

        vm.OptionNext = function () {
            vm.CategoryDetailsDisable = false;
            vm.OptionDetailsDisable = true;
            vm.OptionValueDetailsDisable = false;
        }



       

        vm.NextoptionValue = function (item) {

            vm.OptionValueDetailsDisable = true;
            vm.OptionDetailsDisable = false;
        }

        vm.NextProducts = function (item) {

            vm.OptionValueDetailsDisable = false;
            vm.ProductDetailsDisable = true;

        }


        vm.AddAll = function (ItemProduct,ItemCategory) {


            GetProductsService.GetAllErpProductDetailsSave(ItemProduct, ItemCategory.data).then(function (data) {

            });


        }

        vm.cancel = function () {
            $uibModalStack.dismissAll();
        }
    }
})();


(function () {
    'use strict';

    angular
        .module('app.getProducts')
        .factory('GetProductsService', GetProductsService);

    GetProductsService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'config', 'Settings', 'CategoriesService', 'GeneralService', 'TagsService', 'OptionService', 'ProductsGeneralService', "OptionValuesService"];

    function GetProductsService($http, $q, ngAuthSettings, logger, config, Settings, CategoriesService, GeneralService, TagsService, OptionService, ProductsGeneralService, OptionValuesService) {
        var service = {

            //MarketPlace

            getMarketPlaceFields: getMarketPlaceFields,
            getXmlServicesFields: getXmlServicesFields,
            getMarketPlaceFieldsAnalysis: getMarketPlaceFieldsAnalysis,
            TransferProduct: TransferProduct,
            GetAllProduct: GetAllProduct,
            getCategoryFields: getCategoryFields,
            getOptionFields: getOptionFields,
            GetAllProductSave: GetAllProductSave,
            GetSupplyService: GetSupplyService,
            XMLAnalysis: XMLAnalysis,
            XmlDetailSave: XmlDetailSave,
            XmlProductSave: XmlProductSave,
            GetUpdateProduct: GetUpdateProduct,
            GetXmlProductUpdate: GetXmlProductUpdate,
            RangeUpdate: RangeUpdate,
            GetXmlDataUpdate: GetXmlDataUpdate,
            getErpFields: getErpFields,
            getCategoriesErp: getCategoriesErp,
            getProductErp: getProductErp,
            getProductErpRange: getProductErpRange,
            GetAllErpProductDetailsSave: GetAllErpProductDetailsSave,
            ErpProductAllUpdate: ErpProductAllUpdate,
            getSaveCategory: getSaveCategory,
            TransferProductErp: TransferProductErp
        };

        var serviceBaseHB = ngAuthSettings.apiServiceBaseUri + 'api/hbIntegration/';
        var serviceBaseN11 = ngAuthSettings.apiServiceBaseUri + 'api/N11Integration/';
        var serviceBaseGG = ngAuthSettings.apiServiceBaseUri + 'api/GGIntegration/';
        var serviceBaseTR = ngAuthSettings.apiServiceBaseUri + 'api/trendyolIntegration/';
        var serviceBaseProduct = ngAuthSettings.apiServiceBaseUri + 'api/getProduct/';
        var serviceBaseXml = ngAuthSettings.apiServiceBaseUri + 'api/XmlServices/';
        var serviceBaseNetsim = ngAuthSettings.apiServiceBaseUri + 'api/Netsim/';
        //MarketPlace

        var MarketPlaceFields = [
            {
                key: "marketType",
                type: "floatRadio",
                className: "Radio",
                templateOptions: {
                    options: [
                        {
                            "Name": "N11",
                            "Id": "n11"
                        },
                        {
                            "Name": "GittiGidiyor",
                            "Id": "gg"
                        },
                        {
                            "Name": "Hepsiburada",
                            "Id": "hb"
                        },
                        {
                            "Name": "Trendyol",
                            "Id": "tr"
                        }
                    ]
                }
            },
            {
                key: "Rate",
                type: "floatInput",
                templateOptions: {
                    resource: "form.rate",
                    label: "Komisyon",
                    placeholder: "Komisyon",
                    numberMask: 2,
                    class: "col-xs-2"
                }
            }
        ];


        var ErpFields = [
            {
                key: "erpType",
                type: "floatRadio",
                className: "Radio",
                templateOptions: {
                    options: [
                        {
                            "Name": "Netsim",
                            "Id": "netsim"
                        }
                    ]
                }
            }
        ];


        var XmlServicesFields = [
            {
                key: "Name",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    resource: "form.urlName",
                    label: "Url",
                    placeholder: "Url",
                    class: "col-xs-6"
                }
            }, 
            {
                key: "Url",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    resource: "form.urlXml",
                    label: "Url",
                    placeholder: "Url",
                    class: "col-xs-6"
                }
            },
            {
                key: "Username",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.XmlUsername",
                    label: "Stock",
                    placeholder: "Stock",
                    class: "col-xs-6"
                }
            },
            {
                key: "Password",
                type: "floatInput",
                templateOptions: {
                    type: "text",
                    resource: "form.XmlPassword",
                    label: "Stock",
                    placeholder: "Stock",
                    class: "col-xs-6"
                }
            },
            {
                key: "DefaultStock",
                type: "floatInput",
                templateOptions: {
                    required: true,
                    type: "text",
                    resource: "form.DefaultStock",
                    label: "Stock",
                    placeholder: "Stock",
                    class: "col-xs-6"
                }
            }
        ];

        var MarketPlaceFieldsAnalysis = [
            {
                key: "marketType",
                type: "floatRadio",
                className: "Radio",
                templateOptions: {
                    options: [
                        {
                            "Name": "N11",
                            "Id": "n11"
                        },
                        {
                            "Name": "GittiGidiyor",
                            "Id": "gg"
                        },
                        {
                            "Name": "Hepsiburada",
                            "Id": "hb"
                        },
                        {
                            "Name": "Trendyol",
                            "Id": "tr"
                        }
                    ]
                }
            }
        ];

        var CategoryFields = [
            {
                key: "Categories",
                type: "floatTreeMultiSelect",
                templateOptions: {
                    label: "Kategori",
                    resource: "form.categories",
                    topProp: 'TopCategoryId',
                    required: true,
                    placeholder: "Yeni Kategori Olarak Kaydet",
                    options: [{
                        Name: "Yükleniyor..."
                    }]
                },
                controller: [
                    "$scope",
                    function ($scope) {
                        $scope.first = true;
                        $scope.$watch("model.Categories", function (newValue, oldValue) {
                            if ($scope.first && newValue) {
                                $scope.first = false;
                                ProductsGeneralService.getCategories().then(function (response) {
                                    $scope.to.options = response;
                                });
                            }
                        });
                    }
                ]
            }
        ]

        return service;

        /////////////////////////////////////////

        //MarketPlace

        function getMarketPlaceFields() {
            return MarketPlaceFields;
        }
        function getErpFields() {
            return ErpFields;
        }


        function getMarketPlaceFieldsAnalysis() {
            return MarketPlaceFieldsAnalysis;
        }
        function getCategoryFields() {
            return CategoryFields;
        }
        function getOptionFields() {
            return OptionFields;
        }


        function getXmlServicesFields() {
            return XmlServicesFields;
        }


        function TransferProduct(Params1) {
            var deferred = $q.defer();

            var categoryData = "1";
            var market = Params1[0].formControl.$viewValue;
            if (market == "n11") {
                $http.get(serviceBaseN11 + "GetAllProduct", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            }
            else if (market == "gg") {
                $http.get(serviceBaseGG + "GetAllProduct", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            }
            else if (market == "hb") {
                $http.get(serviceBaseHB + "GetAllProduct", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            }
            else if (market == "tr") {
                $http.get(serviceBaseTR + "GetAllProduct", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
                return deferred.promise;
            }

        }

        function GetAllProduct(Params1) {
            var deferred = $q.defer();

            var categoryData = "1";

            var market = Params1.MarketPlace[0].formControl.$viewValue;
            if (market == "n11") {
                $http.get(serviceBaseN11 + "GetAllProductDetails", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
            }
            
            else if (market == "gg") {
                $http.get(serviceBaseGG + "GetAllProductDetails", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
            }
           
            //else if (market == "hb") {
            //    $http.get(serviceBaseHB + "GetAllProduct", {
            //        params: { CategoryStatu: categoryData }
            //    })
            //        .then(getComplete)
            //        .catch(getFailed);

            //    function getComplete(response) {
            //        deferred.resolve(response.data);
            //    }

            //    function getFailed(error) {
            //        deferred.reject(error);
            //    }
            //    return deferred.promise;
            //}
            else if (market == "tr") {
                $http.get(serviceBaseTR + "GetAllProductDetails", {
                    params: { CategoryStatu: categoryData }
                })
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
            }
            return deferred.promise;
        }

        function GetAllProductSave(Params1) {
            var deferred = $q.defer();
            $http.post(serviceBaseProduct + "GetAllProductDetailsSave", Params1.data)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;

        }

        function GetSupplyService(filterParams) {
            var deferred = $q.defer();

            $http.get(serviceBaseXml + "SupplyList", {
                params: filterParams
            })
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function XMLAnalysis(filterParams) {

            var deferred = $q.defer();

            $http.post(serviceBaseXml + "XmlAnalysis", filterParams)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }
            return deferred.promise;
        }


        function XmlDetailSave(filterParams) {
            var deferred = $q.defer();

            $http.post(serviceBaseXml + "XmlDetailSave", filterParams)
                .then(getComplete)
                .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }
           
            return deferred.promise;
        }


        function XmlProductSave(filterParams) {
            var deferred = $q.defer();

            $http.post(serviceBaseXml + "XmlProductSave", filterParams)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function GetUpdateProduct(filterParams) {
            var deferred = $q.defer();

            $http.post(serviceBaseXml + "XmlProductUpdate", filterParams)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function GetXmlProductUpdate(filterParams) {
            var deferred = $q.defer();

            $http.post(serviceBaseXml + "GetXmlProductUpdate", filterParams)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function RangeUpdate(filterParams) {
            var deferred = $q.defer();

            $http.post(serviceBaseXml + "RangeUpdate", filterParams)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }

        function GetXmlDataUpdate(SupplyId) {
            var deferred = $q.defer();

            $http.get(serviceBaseXml + "GetXmlDataUpdate/"+ SupplyId)
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;
        }


        function getCategoriesErp(Params) {
            var deferred = $q.defer();
            if (Params.System[0].formControl.$viewValue === "netsim") {
                $http.get(serviceBaseNetsim + "GetErpCategories/")
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }

                return deferred.promise;
            } else {
                return deferred.promise;
            }
            
        }

        function getSaveCategory(Params) {
            var deferred = $q.defer();
                $http.post(serviceBaseNetsim + "GetErpCategoriesSave", Params)
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }

                return deferred.promise;
           

        }
        function getProductErp(Params) {
            var deferred = $q.defer();
            if (Params[0].formControl.$viewValue === "netsim") {
                $http.get(serviceBaseNetsim + "GetErpProduct/")
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }

                return deferred.promise;
            }

        }

        function TransferProductErp(Params) {
            var deferred = $q.defer();
            console.log(Params);
            if (Params[0].formControl.$viewValue === "netsim") {
                $http.get(serviceBaseNetsim + "TransferProductErp/")
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }

                return deferred.promise;
            }

        }

        function getProductErpRange(IsUpdate,startDate, endDate, CategoryList) {

            var deferred = $q.defer();

            $http.post(serviceBaseNetsim + "GetErpProductRange/" +IsUpdate+ "/"+startDate + "/" + endDate, CategoryList)
                    .then(getComplete)
                    .catch(getFailed);

                function getComplete(response) {
                    deferred.resolve(response.data);
                }

                function getFailed(error) {
                    deferred.reject(error);
                }

                return deferred.promise;
            

        }

        function GetAllErpProductDetailsSave(Params1, Params2) {
            var deferred = $q.defer();

            $http.post(serviceBaseNetsim + "GetErpProductDetailsSave/",
                {
                    Product: Params1,
                    Category: Params2
                }
            )
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;


        }
        function ErpProductAllUpdate(Params) {
            var deferred = $q.defer();

            $http.post(serviceBaseNetsim + "ErpProductAllUpdate/", Params
            )
                .then(getComplete)
                .catch(getFailed);

            function getComplete(response) {
                deferred.resolve(response.data);
            }

            function getFailed(error) {
                deferred.reject(error);
            }

            return deferred.promise;


        }




    }
})();

(function () {
	"use strict";

	angular
		.module('app.invoices')
		.factory('InvoiceService', InvoiceService);

	InvoiceService.$inject = ['$http', '$q', 'ngAuthSettings', 'logger', 'SharedData', '$timeout', 'Settings'];

	function InvoiceService($http, $q, ngAuthSettings, logger, SharedData, $timeout, Settings) {

		var service = {
			getInvoices: getInvoices,
			downloadInvoice: downloadInvoice,
			getInvoiceTypes: getInvoiceTypes,
			getUpdateInvoices: getUpdateInvoices,
			getEarsivHtml: getEarsivHtml,
			getEfaturaHtml: getEfaturaHtml,
			getHtmlInvoice: getHtmlInvoice,
			getIncomeInvoices: getIncomeInvoices,
			getIncomeEfaturaHtml: getIncomeEfaturaHtml,
			downloadIncomeInvoice: downloadIncomeInvoice,
			setIncomeEInvoiceAnswer: setIncomeEInvoiceAnswer,
			sendSelectedEInvoice: sendSelectedEInvoice,
			sendEInvoice: sendEInvoice,
			getPreviewInvoice: getPreviewInvoice,
			getDownloadInvoice: getDownloadInvoice,
			acceptInvoices: acceptInvoices,
			rejectInvoices: rejectInvoices

		};

		var serviceBase = ngAuthSettings.apiServiceBaseUri;


		var invoiceFields = [{
			key: "Name",
			type: "floatInput",
			templateOptions: {
				required: true,
				type: "text",
				label: "Adı",
				placeholder: "Adı",
				resource: "form.name"
			}
		}
		];


		return service;

		function getInvoices(filterParams) {
			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/All", {
					params: filterParams
				})
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getIncomeInvoices(filterParams) {
			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/IncomeEInvoiceInvoiceNum", {
				params: filterParams
			})
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getUpdateInvoices(filterParams) {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/updateAllInvoices", {
				params: filterParams
			})
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getInvoiceTypes() {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/getInvoiceTypes")
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				console.log(response.data);
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
        }

		function downloadInvoice(invoiceNumber, kindOfInvoice) {

			if (kindOfInvoice == "2") {
				return downloadEarsivPdf(invoiceNumber);
			}
			else {
				return downloadFaturaPdf(invoiceNumber);
            }

		}

		function downloadEarsivPdf(invoiceNumber) {

			window.open('/api/EInvoice/downloadEArchiveInvoiceNum/' + invoiceNumber, '_blank');


			//var deferred = $q.defer();

			//$http.get(serviceBase + "api/EInvoice/downloadEArchiveInvoiceNum/" + invoiceNumber)
			//	.then(getInvoicesComplete)
			//	.catch(getInvoicesFailed);

			//function getInvoicesComplete(response) {
			//	deferred.resolve(response.data);
			//}

			//function getInvoicesFailed(error) {
			//	deferred.reject(error);
			//}
			//return deferred.promise;
		}

		function downloadFaturaPdf(invoiceNumber) {


			window.open('/api/EInvoice/downloadEFaturaInvoiceNum/' + invoiceNumber, '_blank');

			//	var deferred = $q.defer();

			//	$http.get(serviceBase + "api/EInvoice/downloadEFaturaInvoiceNum/" + invoiceNumber)
			//		.then(getInvoicesComplete)
			//		.catch(getInvoicesFailed);

			//	function getInvoicesComplete(response) {
			//		deferred.resolve(response.data);
			//	}

			//	function getInvoicesFailed(error) {
			//		deferred.reject(error);
			//	}
			//	return deferred.promise;

		}

		function downloadIncomeInvoice(UUID) {

			window.open('/api/EInvoice/downloadIncomeEFatura/' + UUID, '_blank');
		}

		function getPreviewInvoice(invoiceId) {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/previewEInvoiceId/" + invoiceId)
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise
		}

		function getDownloadInvoice(invoiceId) {

			window.open('/api/EInvoice/downloadEInvoiceId/' + invoiceId, '_blank');

		}

		function getHtmlInvoice(invoiceNumber, kindOfInvoice) {

			if (kindOfInvoice == "2") {
				return getEarsivHtml(invoiceNumber);
			}
			else {
				return getEfaturaHtml(invoiceNumber);
			}
        }

		function getEarsivHtml(invoiceNumber) {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/previewEArchiveInvoiceNum/" + invoiceNumber)
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getEfaturaHtml(invoiceNumber) {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/previewEInvoiceInvoiceNum/" + invoiceNumber)
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function getIncomeEfaturaHtml(UUID) {

			var deferred = $q.defer();

			$http.get(serviceBase + "api/EInvoice/previewIncomeEInvoiceUUID/" + UUID)
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function setIncomeEInvoiceAnswer(answer) {

			var deferred = $q.defer();
			$http.post(serviceBase + "api/EInvoice/incomeEInvoiceAnswer", JSON.stringify(answer))
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function acceptInvoices(UUID) {

			var answer =
			{
				"UUID": UUID,
				"answerType": "ACCEPTED",
				"answerNote": "Kabul edildi",
				"isDirectSend": "true"
			};

			var jsonAnswer = JSON.stringify(answer);

			var deferred = $q.defer();
			$http.post(serviceBase + "api/EInvoice/incomeEInvoiceAnswer", JSON.stringify(jsonAnswer))
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}

		function rejectInvoices(UUID) {

			var answer =
			{
				"UUID": UUID,
				"answerType": "REJECTED",
				"answerNote": "Red edildi",
				"isDirectSend": "false"
			};
			var jsonAnswer = JSON.stringify(answer);

			var deferred = $q.defer();
			$http.post(serviceBase + "api/EInvoice/incomeEInvoiceAnswer", JSON.stringify(jsonAnswer))
				.then(getInvoicesComplete)
				.catch(getInvoicesFailed);

			function getInvoicesComplete(response) {
				deferred.resolve(response.data);
			}

			function getInvoicesFailed(error) {
				deferred.reject(error);
			}
			return deferred.promise;
		}


		function sendSelectedEInvoice(orderData) {
			var deferred = $q.defer();
			$http.post(serviceBase + 'api/EInvoice/sendInvoiceOrders/', orderData)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error.data);
			}
			return deferred.promise;
		}


		function sendEInvoice(orderId) {
			var deferred = $q.defer();
			$http.get(serviceBase + 'api/EInvoice/sendInvoice/' + orderId)
				.then(complete)
				.catch(failed);

			function complete(response) {
				deferred.resolve(response.data);
			}

			function failed(error) {
				deferred.reject(error.data);
			}
			return deferred.promise;
		}s

	}

})();